<template>
  <cui-acl  :roles="['HRsettings']" redirect v-if="loaded">
    <h4>
      <span>HR Instillinger | Endre standardkontrakt</span>
    </h4>
    <div class="card">
      <div class="card-body">
        <!-- <h5 class="mb-4">
          <strong>Two Columns</strong>
        </h5> -->
        <a-form :form="form" layout="vertical" @submit="handleSubmit">
          <div class="row">
            <div class="col-md-3">
              <a-form-item label="Selskap">
                <a-select
                  v-decorator="[
                  'company',
                  {
                    initialValue: 'Skycall',
                    rules: [{ required: true, message: 'Venligst velg et selskap' }],
                  }
                  ]"
                  :options="companies"
                  v-model="selectedCompany"
                />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Prosjekt">
                <a-select
                  v-decorator="[
                  'project',
                  {
                    rules: [{ required: true, message: 'Venligst velg et prosjekt' }],
                  }
                  ]"
                  :options="projects.filter(el => el.company === selectedCompany)"
                />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Avdelinger">
                <a-select
                  mode="multiple"
                  v-decorator="[
                  'department',
                  ]"
                  :options="departments"
                />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Tilgang til kontrakt">
                <a-select
                  v-decorator="[
                  'role',
                  {
                    rules: [{ required: true, message: 'Venligst velg en stillingstype' }],
                  }
                  ]"
                  :options="[{value: 'HRcontractStandard', label: 'Standard' },{value: 'HRcontractManager', label: 'Leder' }]"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Tittel på kontrakt">
                <a-input
                  placeholder="Unik tittel for stillingen"
                  v-decorator="['title', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Stillingstittel">
                <a-input
                  placeholder="Stillingstittel"
                  v-decorator="['positionTitle', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Yrkeskode">
                <a-select show-search :filter-option="filterOption"
                  v-decorator="['occupationCode', { rules:[{required: true, message: 'Kontrakten må ha en yrkeskode'}]}]"
                >
                  <a-select-option :value="option.value" v-for="option in occupationCodes" :key="option.value">
                    {{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Stillingstype (Fulltid/Deltid)">
                <a-select
                  v-decorator="[
                  'employmentType',
                  {
                    rules: [{ required: true, message: 'Venligst velg en stillingstype' }],
                  }
                  ]"
                  :options="[{value: 'full-time', label: 'Fulltid' },{value: 'part-time', label: 'Deltid' },{value: 'consultant', label: 'Konsulent' },]"
                />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Standard sillingsprosent (uten % tegn)">
                <a-input
                  placeholder="100%"
                  v-decorator="['percentage', { rules:[{required: true, message: 'Kontrakten må ha en stillingsprosent'}]}]"
                />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Belønningsform">
                <a-select
                  v-decorator="[
                  'salary.type',
                  {
                    rules: [{ required: true, message: 'Venligst velg en belønningsform' }],
                  },
                ]"
                :options="salaryTypes"
                />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Beløp">
                <a-input
                type="number"
                  placeholder="Fast/Garanti-månedslønn"
                  v-decorator="['salary.amount']"
                />
              </a-form-item>
            </div>
          </div>
          <!-- <div class="row">
              <div class="col-md-12" v-for="(field, id) in customFields" :key="id">
                <a-form-item :label="field.name">
                  <div class="row">
                    <div class="col-4">
                      <a-form-item label="Navn Parameter">
                        <a-input
                          v-model="field.name"
                        />
                      </a-form-item>
                    </div>
                    <div class="col-4">
                      <a-form-item label="Variabel navn">
                        <a-input
                          v-model="field.variable"
                        />
                      </a-form-item>
                    </div>
                    <div class="col-4">
                      <a-form-item label="Variabel Type">
                        <a-select
                          v-model="field.type"
                          :options="[{value: 'input', label: 'Tekst' },{value: 'select', label: 'Valg' }]"
                        />
                      </a-form-item>
                    </div>
                    <div class="col-12" v-if="field.type === 'select'">
                      <div class="row">
                        <div class="col-4" v-for="(option, id) in field.options" :key="id">
                          <a-form-item :label="'Valg '.concat(id+1)">
                            <a-input v-model="field.options[id]" />
                          </a-form-item>
                        </div>
                        <div class="col-md-14">
                          <a-button type="secondary"  class="mt-3" @click="field.options.push('Valg')">Legg til valg</a-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-form-item>
              </div>
              <div class="col-md-12">
                  <a-button type="secondary"  class="mt-3" @click="customFields.push({name: 'Ny parameter', options: []})">Legg til custom parameter</a-button>
              </div>
          </div> -->
          <div class="row mt-3">
            <div class="col-12">
              <a-form-item label="Kontraktsmal">
                <div class="clearfix">
                <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
                  <a-button> <a-icon type="upload" /> Select File </a-button>
                </a-upload>
                </div>
              </a-form-item>
            </div>
            <div class="col-xl-12 col-lg-12">
              <a-form-item label="Standard Roller">
                <user-roles
                  v-if="['userAdmin'].some(r => $store.state.user.roles.indexOf(r) >= 0)"
                  v-model="roles"
                />
                <p v-else>For sikkerhetsårsaker, så har du ikke muligheten til å definere standard roller.</p>
              </a-form-item>
            </div>
            <div class="col-12">
              <a-form-item>
                <button type="primary" class="btn btn-light px-5" @loading="loading">Lagre</button>
              </a-form-item>
            </div>
          </div>
        </a-form>
        <a-popconfirm
          title="Er du sikker du ønsker å slette standard kontrakten permanent?"
          ok-text="Ja"
          cancel-text="Nei"
          @confirm="handleDelete"
        >
          <a-button type="secondary"  class="mt-3">Slett standardkontrakt</a-button>
        </a-popconfirm>
      </div>
    </div>
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import userRoles from '@/components/custom/user/roles/displayRoles'

import { firestore, storage } from '@/services/firebase'
import moment from 'moment'
import { openNotification } from '@/services/powerAutomate'
import { getOccupationCodes, salaryTypes } from '@/services/common/hr'

export default {
  components: {
    cuiAcl,
    userRoles,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      customFields: [],
      companies: [],
      projects: [],
      departments: [],
      roles: [],
      fileList: [],
      loading: false,
      loaded: false,
      selectedCompany: '',
      occupationCodes: [],
      salaryTypes,
    }
  },
  methods: {
    moment,
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = [file]
      return false
    },
    async handleUpload(fileName) {
      const { fileList } = this
      const file = fileList[0]
      let uploadedFile
      return storage.ref('/contracts').child(fileName).put(file).then((snapshot) => {
        console.log(snapshot)
        uploadedFile = {
          fullPath: snapshot.metadata.fullPath,
          name: snapshot.metadata.name,
          timeCreated: snapshot.metadata.timeCreated,
        }
        return snapshot.ref.getDownloadURL()
      }).then(url => {
        uploadedFile.url = url
        return uploadedFile
      }).catch(err => {
        console.log(err)
        throw new Error('could not uplaod file')
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true
          const data = values
          data.defaultRoles = this.roles
          data.date = moment().format('YYYY-MM-DDTHH:mm')
          data.tripletex = {
            occupationCode: 6080,
          }
          if (this.fileList.length && this.fileList[0].status !== 'done') {
            data.docTemplate = await this.handleUpload(data.title.concat('.docx'))
          } else {
            data.docTemplate = await firestore.collection('settings').doc('contracts').collection('contracts').doc(this.$route.params.id).get()
              .then(doc => {
                return doc.data().docTemplate
              })
          }
          console.log(data)
          // put url to fileList
          this.fileList[0] = {
            ...data.docTemplate,
            status: 'done',
            uid: '1',
          }
          firestore.collection('settings').doc('contracts').collection('contracts').doc(this.$route.params.id).set(data)
            .then(() => {
              openNotification('Kontrakt endringer lagret')
            }).catch(err => {
              console.log(err)
              openNotification('Feil ved opprettelse av kontrakt, vennligst prøv igjen')
            })
          this.loading = false
        }
      })
    },
    handleDelete() {
      firestore.collection('settings').doc('contracts').collection('contracts').doc(this.$route.params.id).delete()
      this.$router.push({ path: '/hr/settings' })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  mounted() {
    getOccupationCodes().then(occupationCodes => {
      console.log(occupationCodes)
      this.occupationCodes = occupationCodes
    })

    firestore.collection('settings').doc('companies').collection('companies').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const company = {
            label: doc.data().displayName,
            value: doc.id,
          }
          this.companies.push(company)
        })
        return firestore.collection('settings').doc('projects').collection('projects').get()
      }).then(snapshot => {
        snapshot.forEach(doc => {
          const project = {
            label: doc.data().name,
            value: doc.id,
            company: doc.data().company,
          }
          this.projects.push(project)
        })
        return firestore.collection('settings').doc('departments').collection('departments').get()
      }).then(snapshot => {
        snapshot.forEach(doc => {
          const department = {
            label: doc.data().name,
            value: doc.id,
          }
          this.departments.push(department)
        })
        this.loaded = true
        return firestore.collection('settings').doc('contracts').collection('contracts').doc(this.$route.params.id).get()
      }).then(doc => {
        this.form.setFieldsValue(doc.data())
        this.roles = doc.data().defaultRoles
        this.fileList.push({
          name: doc.data().docTemplate.name,
          url: doc.data().docTemplate.url,
          status: 'done',
          uid: '1',
        })
        this.selectedCompany = doc.data().company
      })
  },
}
</script>
