var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('cui-acl',{attrs:{"roles":['HRsettings'],"redirect":""}},[_c('h4',[_c('span',[_vm._v("HR Instillinger | Endre standardkontrakt")])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('a-form-item',{attrs:{"label":"Selskap"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'company',
                {
                  initialValue: 'Skycall',
                  rules: [{ required: true, message: 'Venligst velg et selskap' }],
                }
                ]),expression:"[\n                'company',\n                {\n                  initialValue: 'Skycall',\n                  rules: [{ required: true, message: 'Venligst velg et selskap' }],\n                }\n                ]"}],attrs:{"options":_vm.companies},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1)],1),_c('div',{staticClass:"col-md-3"},[_c('a-form-item',{attrs:{"label":"Prosjekt"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'project',
                {
                  rules: [{ required: true, message: 'Venligst velg et prosjekt' }],
                }
                ]),expression:"[\n                'project',\n                {\n                  rules: [{ required: true, message: 'Venligst velg et prosjekt' }],\n                }\n                ]"}],attrs:{"options":_vm.projects.filter(function (el) { return el.company === _vm.selectedCompany; })}})],1)],1),_c('div',{staticClass:"col-md-3"},[_c('a-form-item',{attrs:{"label":"Avdelinger"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'department' ]),expression:"[\n                'department',\n                ]"}],attrs:{"mode":"multiple","options":_vm.departments}})],1)],1),_c('div',{staticClass:"col-md-3"},[_c('a-form-item',{attrs:{"label":"Tilgang til kontrakt"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'role',
                {
                  rules: [{ required: true, message: 'Venligst velg en stillingstype' }],
                }
                ]),expression:"[\n                'role',\n                {\n                  rules: [{ required: true, message: 'Venligst velg en stillingstype' }],\n                }\n                ]"}],attrs:{"options":[{value: 'HRcontractStandard', label: 'Standard' },{value: 'HRcontractManager', label: 'Leder' }]}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Tittel på kontrakt"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]),expression:"['title', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]"}],attrs:{"placeholder":"Unik tittel for stillingen"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Stillingstittel"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['positionTitle', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]),expression:"['positionTitle', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]"}],attrs:{"placeholder":"Stillingstittel"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Yrkeskode"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['occupationCode', { rules:[{required: true, message: 'Kontrakten må ha en yrkeskode'}]}]),expression:"['occupationCode', { rules:[{required: true, message: 'Kontrakten må ha en yrkeskode'}]}]"}],attrs:{"show-search":"","filter-option":_vm.filterOption}},_vm._l((_vm.occupationCodes),function(option){return _c('a-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1)],1),_c('div',{staticClass:"col-md-3"},[_c('a-form-item',{attrs:{"label":"Stillingstype (Fulltid/Deltid)"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'employmentType',
                {
                  rules: [{ required: true, message: 'Venligst velg en stillingstype' }],
                }
                ]),expression:"[\n                'employmentType',\n                {\n                  rules: [{ required: true, message: 'Venligst velg en stillingstype' }],\n                }\n                ]"}],attrs:{"options":[{value: 'full-time', label: 'Fulltid' },{value: 'part-time', label: 'Deltid' },{value: 'consultant', label: 'Konsulent' } ]}})],1)],1),_c('div',{staticClass:"col-md-3"},[_c('a-form-item',{attrs:{"label":"Standard sillingsprosent (uten % tegn)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['percentage', { rules:[{required: true, message: 'Kontrakten må ha en stillingsprosent'}]}]),expression:"['percentage', { rules:[{required: true, message: 'Kontrakten må ha en stillingsprosent'}]}]"}],attrs:{"placeholder":"100%"}})],1)],1),_c('div',{staticClass:"col-md-3"},[_c('a-form-item',{attrs:{"label":"Belønningsform"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'salary.type',
                {
                  rules: [{ required: true, message: 'Venligst velg en belønningsform' }],
                } ]),expression:"[\n                'salary.type',\n                {\n                  rules: [{ required: true, message: 'Venligst velg en belønningsform' }],\n                },\n              ]"}],attrs:{"options":_vm.salaryTypes}})],1)],1),_c('div',{staticClass:"col-md-3"},[_c('a-form-item',{attrs:{"label":"Beløp"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['salary.amount']),expression:"['salary.amount']"}],attrs:{"type":"number","placeholder":"Fast/Garanti-månedslønn"}})],1)],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12"},[_c('a-form-item',{attrs:{"label":"Kontraktsmal"}},[_c('div',{staticClass:"clearfix"},[_c('a-upload',{attrs:{"file-list":_vm.fileList,"remove":_vm.handleRemove,"before-upload":_vm.beforeUpload}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Select File ")],1)],1)],1)])],1),_c('div',{staticClass:"col-xl-12 col-lg-12"},[_c('a-form-item',{attrs:{"label":"Standard Roller"}},[(['userAdmin'].some(function (r) { return _vm.$store.state.user.roles.indexOf(r) >= 0; }))?_c('user-roles',{model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}}):_c('p',[_vm._v("For sikkerhetsårsaker, så har du ikke muligheten til å definere standard roller.")])],1)],1),_c('div',{staticClass:"col-12"},[_c('a-form-item',[_c('button',{staticClass:"btn btn-light px-5",attrs:{"type":"primary"},on:{"loading":_vm.loading}},[_vm._v("Lagre")])])],1)])]),_c('a-popconfirm',{attrs:{"title":"Er du sikker du ønsker å slette standard kontrakten permanent?","ok-text":"Ja","cancel-text":"Nei"},on:{"confirm":_vm.handleDelete}},[_c('a-button',{staticClass:"mt-3",attrs:{"type":"secondary"}},[_vm._v("Slett standardkontrakt")])],1)],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }